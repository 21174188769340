import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fab,
  Box,
  AppBar,
  Toolbar,
  CircularProgress,
  Chip,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  IconButton,
  Skeleton,
} from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LogoutIcon from '@mui/icons-material/Logout';

const LoginScreen = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    if (username === 'zulekha' && password === 'Z@123') {
      localStorage.setItem('isLoggedIn', 'true');
      onLogin();
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      <Box component="form" sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleLogin}
        >
          Sign In
        </Button>
        {error && (
          <Typography color="error" align="center">
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const DomainStatusChecker = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [domains] = useState([    
    'https://unityperformance.net/',
    'https://andrewcohenlegal.com/',
    'https://crownlawgroup.com/',
    'https://legalcounseling.com/',
    'https://teresabeyers.com/',
    'https://premierlegalcenter.com/',
    'https://jmllaw.com/',    
    'https://joelovretovich.com/',
    'https://sfdefenselaw.com/',
    'https://chicagocriminaldefenders.com/',
    'https://lasvegascrimelawyer.com/',
    'https://koszdin.com/',
    'https://ragerlawoffices.com/',
    'https://azadianlawgroup.com/',
    'https://kornberglawfirm.com/',
    'https://sangabrielinjurylawyers.com/',
    'https://randolphassociates.com/',
    'https://lacolorectaldoctor.com/',
    'https://venturainjurylawfirm.com/',
    'https://baglawgroup.com/',
    'https://slmfamilylaw.com/',
    'https://southbaylawyer.com/',
    'https://szcomplaw.com/',
    'https://snohomishcountyinjuryattorney.com/',
  ]);
  const [domainStatuses, setDomainStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(true);

  useEffect(() => {
    document.title = "CID 2.0 | BEARDOG";
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#4ecdc4' : '#ff6b6b',
      },
      secondary: {
        main: darkMode ? '#ff6b6b' : '#4ecdc4',
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchDomainStatuses = useCallback(async () => {
    setIsLoading(true);
    const promises = domains.map(domain => {
      return axios.get(`${domain}wp-json/clientids/v1/is_client/?t=${Date.now()}`)
        .then(response => {
          let status = 'warning';
          let message = 'Client ID: Missing';
          if (!response.data || response.data !== true) {
            status = 'success';
            message = 'Client ID: OK';
          }
          return { domain, status, message };
        })
        .catch(() => ({ domain, status: 'error', message: 'Error fetching status' }));
    });

    const results = await Promise.all(promises);
    setDomainStatuses(results);
    setIsLoading(false);

    localStorage.setItem('cachedDomainStatuses', JSON.stringify({
      timestamp: Date.now(),
      data: results
    }));
    setIsLoading(false);
  }, [domains]);

  useEffect(() => {
    if (isLoggedIn) {
      const cachedData = localStorage.getItem('cachedDomainStatuses');
      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const cacheAge = Date.now() - timestamp;

        if (cacheAge < 5 * 60 * 1000) {
          setDomainStatuses(data);
          setIsLoading(false);
          return;
        }
      }

      fetchDomainStatuses();
    }
  }, [fetchDomainStatuses, isLoggedIn]);

  const handleRefresh = () => {
    setIsLoading(true);
    fetchDomainStatuses().finally(() => setIsLoading(false));
  };

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'success':
        return <CheckCircleOutlineIcon color="success" />;
      case 'warning':
        return <WarningAmberIcon color="warning" />;
      case 'error':
        return <ErrorOutlineIcon color="error" />;
      default:
        return null;
    }
  };

  const getStatusChip = (status, message) => {
    return (
      <Chip
        icon={getStatusIcon(status)}
        label={message}
        color={status === 'success' ? 'success' : status === 'warning' ? 'warning' : 'error'}
        variant={status === 'warning' ? 'filled' : 'outlined'}
        sx={{
          fontWeight: status === 'warning' ? 'bold' : 'normal',
          animation: status === 'warning' ? 'pulse 2s infinite' : 'none',
          '@keyframes pulse': {
            '0%': {
              boxShadow: '0 0 0 0 rgba(255, 167, 38, 0.7)',
            },
            '70%': {
              boxShadow: '0 0 0 10px rgba(255, 167, 38, 0)',
            },
            '100%': {
              boxShadow: '0 0 0 0 rgba(255, 167, 38, 0)',
            },
          },
        }}
      />
    );
  };


  const renderShimmerTable = () => (
    <TableContainer 
      component={Paper} 
      sx={{ 
        bgcolor: darkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: darkMode ? '#4ecdc4' : '#ff6b6b' }}>No.</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: darkMode ? '#4ecdc4' : '#ff6b6b' }}>Domains</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: darkMode ? '#4ecdc4' : '#ff6b6b' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(domains.length)].map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Skeleton animation="wave" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" width={100} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderShimmerCards = () => (
    <Grid container spacing={2}>
      {[...Array(domains.length)].map((_, index) => (
        <Grid item xs={12} key={index}>
          <Card 
            sx={{ 
              bgcolor: darkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardContent>
              <Skeleton animation="wave" height={30} width="80%" />
              <Skeleton animation="wave" height={24} width={100} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  const renderTable = () => (
    <TableContainer 
      component={Paper} 
      sx={{ 
        bgcolor: darkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: darkMode ? '#4ecdc4' : '#ff6b6b' }}>No.</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: darkMode ? '#4ecdc4' : '#ff6b6b' }}>Domains</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: darkMode ? '#4ecdc4' : '#ff6b6b' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {domainStatuses.map((status, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{status.domain}</TableCell>
              <TableCell>
                {getStatusChip(status.status, status.message)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderCards = () => (
    <Grid container spacing={2}>
      {domainStatuses.map((status, index) => (
        <Grid item xs={12} key={index}>
          <Card 
            sx={{ 
              bgcolor: darkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                {index + 1}. {status.domain}
              </Typography>
              {getStatusChip(status.status, status.message)}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar 
          position="static" 
          sx={{ 
            bgcolor: darkMode ? '#1a1a1a' : '#f0f0f0',
            color: darkMode ? '#ffffff' : '#333333',
          }}
        >
          <Toolbar sx={{ flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', py: isMobile ? 2 : 0 }}>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                flexGrow: 1,
                fontWeight: 'bold',
                fontSize: isMobile ? '1.2em' : '1.5em',
                textAlign: 'center',
                mb: isMobile ? 1 : 0,
                background: darkMode 
                  ? 'linear-gradient(45deg, #4ecdc4, #ff6b6b)'
                  : 'linear-gradient(45deg, #ff6b6b, #4ecdc4)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                animation: 'gradientShift 3s ease infinite',
                '@keyframes gradientShift': {
                  '0%': { backgroundPosition: '0% 50%' },
                  '50%': { backgroundPosition: '100% 50%' },
                  '100%': { backgroundPosition: '0% 50%' },
                },
              }}
            >
              Contact Form 7 - Client ID's Checker | CID 2.0
            </Typography>
            {isLoggedIn && (
              <Button 
                color="inherit" 
                onClick={handleLogout} 
                startIcon={<LogoutIcon />}
                sx={{ mt: isMobile ? 1 : 0 }}
              >
                Logout
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Container component="main" sx={{ mt: 4, mb: 2 }} maxWidth="md">
          {isLoggedIn ? (
            isLoading ? (
              isMobile ? renderShimmerCards() : renderShimmerTable()
            ) : (
              isMobile ? renderCards() : renderTable()
            )
          ) : (
            <LoginScreen onLogin={() => setIsLoggedIn(true)} />
          )}
        </Container>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            bgcolor: darkMode ? '#1a1a1a' : '#f0f0f0',
            color: darkMode ? '#ffffff' : '#333333',
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="body1" align="center">
              © 2024 Contact Form 7 - Client ID's Checker. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
      {isLoggedIn && (
        <>
          <Fab
            color="primary"
            onClick={handleRefresh}
            sx={{
              position: 'fixed',
              bottom: isMobile ? 16 : '50%',
              right: isMobile ? 16 : 24,
              transform: isMobile ? 'none' : 'translateY(60px)',
              width: isMobile ? 40 : 56,
              height: isMobile ? 40 : 56,
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <RefreshIcon />
            )}
          </Fab>
          <Fab
            color="secondary"
            onClick={handleDarkModeToggle}
            sx={{
              position: 'fixed',
              bottom: isMobile ? 16 : '50%',
              left: isMobile ? 16 : 24,
              transform: isMobile ? 'none' : 'translateY(60px)',
              width: isMobile ? 40 : 56,
              height: isMobile ? 40 : 56,
            }}
          >
            {darkMode ? (
              <Brightness7Icon fontSize={isMobile ? 'small' : 'medium'} />
            ) : (
              <Brightness4Icon fontSize={isMobile ? 'small' : 'medium'} />
            )}
          </Fab>
        </>
      )}
    </ThemeProvider>
  );
};

export default DomainStatusChecker;